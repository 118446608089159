import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriseViewComponent } from './enterprise-view/enterprise-view.component';
import { SeverityViewComponent } from './severity-view/severity-view.component'
import { PlantViewComponent } from './plant-view/plant-view.component';
import { TrendChartComponent } from './trend-chart/trend-chart.component';
import { SeverityTrendChartComponent } from './severity-trend-chart/severity-trend-chart.component';
import { ChartSideNavComponent } from './chart-side-nav/chart-side-nav.component';
import { SiteTrendChartComponent } from './site-trend-chart/site-trend-chart.component';
import { TrendSampleRateComponent } from './trend-sample-rate/trend-sample-rate.component';
import {AdminComponent} from "./admin/admin.component";
import { GeaMesLoginComponent, GeaMesCognitoAuthGuard, GeaMesCognitoModule, GeaMesAuthInterceptor, COGNITO_CONFIG, APPLICATION_NAME, ALLOW_SCREENAUTH, APIGATEWAY_CONFIG } from '@gea-mes/cognito';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy, PlatformLocation} from "@angular/common";
import { appinfo } from './appinfo';
import { GEA_MES_NAVBAR_COGNITO_CONFIG,GEA_MES_NAVBAR_APPLICATION_NAME,GEA_MES_NAVBAR_MENU_SERVICE,GEA_MES_NAVBAR_ENV_PROD_STATUS, GEA_ENVIROMENT, NavItem} from '@gea-mes/navbar';
import { BehaviorSubject } from 'rxjs';
import { AnalysisHomeComponent } from './analysis/analysis-home/analysis-home.component';
import { AlarmDetailViewComponent } from './alarm-detail-view/alarm-detail-view.component';

const appRoutes: Routes = [
  { path: 'login', component: GeaMesLoginComponent },
  {
    path: '', canActivate: [GeaMesCognitoAuthGuard],
  children: [
    { path: '', component: EnterpriseViewComponent }, 
    { path: 'apps', component: EnterpriseViewComponent },
    { path: 'plantView/:plant', component: PlantViewComponent },
    { path: 'details/:plant', component: SeverityViewComponent },
    { path: ':plant/:resultid', component: AlarmDetailViewComponent},
    { path: 'admin', component: AdminComponent },
    { path: 'charts', component: ChartSideNavComponent,
        children:[
          {path: "trendChart/:plant", component: TrendChartComponent},
          {path: "trendChart/:plant/:line", component: TrendChartComponent},
          {path: 'severityTrendChart/:plant', component: SeverityTrendChartComponent},
          {path: 'siteTrendChart/:plant', component: SiteTrendChartComponent},
          {path: 'sampleRate/:plant', component: TrendSampleRateComponent}
        ]
    },
    {path: 'analysis/:plant', component: AnalysisHomeComponent}
    ]
  },
  { path: '**', component: EnterpriseViewComponent }
];

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
    imports: [GeaMesCognitoModule, RouterModule.forRoot(appRoutes, {})],
    providers: [
      { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },
      { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
      { provide: LocationStrategy, useClass: PathLocationStrategy },
      { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
      { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
      { provide: ALLOW_SCREENAUTH, useValue: environment.AllowScreenAuth },
      { provide: GEA_MES_NAVBAR_APPLICATION_NAME,useValue: appinfo.name.toUpperCase()}, 
      { provide: GEA_MES_NAVBAR_COGNITO_CONFIG, useValue: environment.cognitoOptions }, 
      { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
      { provide: GEA_ENVIROMENT, useValue: environment },
      { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue: new BehaviorSubject<NavItem[]>([]) }    
    ],
    exports: [RouterModule]
  })  
export class AppRoutingModule { }
